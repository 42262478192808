import React from 'react';
import Cookies from 'universal-cookie';

import NavHead from '../NavHead';
import CookieWarning from '../CookieWarning';
import LayoutContainer from '../EditorPanel/ManageSections/LayoutContainer';
import Image from '../Image';
import { formColor } from '../../helper';

import { bg } from './styles.module.css';

let IntersectionObserver;
if (typeof window !== 'undefined') {
  if (window.IntersectionObserver) IntersectionObserver = window.IntersectionObserver;
  else IntersectionObserver = require('intersection-observer'); // eslint-disable-line global-require
}

const cookies = new Cookies();

const documentReady = (callbackFunction) => {
  if (typeof document !== 'undefined') {
    if (document.readyState !== 'loading') {
      callbackFunction();
    } else {
      document.addEventListener('DOMContentLoaded', callbackFunction);
    }
  }
};

class ErrorView extends React.Component {
  constructor(props) {
    super(props);

    cookies.get('cookiesAccepted');

    this.state = {
      matches: null,
      isScrolling: false,
    };

    this.sentinel = React.createRef();

    this.updateMatches = this.updateMatches.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

    if (this.props.pageContext.navigation.styles.fixed_top) {
      const elem = this.sentinel.current;
      if (elem) {
        const observer = new IntersectionObserver(this.handleScroll);
        observer.observe(elem);
      }
    }
  }

  componentWillUnmount() {
    if (this.mediaQueryList) {
      this.mediaQueryList.removeListener(this.updateMatches);
    }
  }

  handleScroll(entries) {
    if (this.props.pageContext.navigation.styles.fixed_top) {
      this.setState({
        isScrolling: !entries[entries.length - 1].isIntersecting,
      });
    }
  }

  updateMatches() {
    this.setState({
      matches: this.mediaQueryList.matches,
    });
  }

  render() {
    let ready = false;
    if (typeof document !== 'undefined') {
      documentReady(() => {
        ready = true;
      });
    }

    const { pageContext } = this.props;
    const text = pageContext.language === 'FI' ? 'Sivua ei löydy' : 'Page not found';
    const overlay = pageContext.navigation.styles.overlay || false;

    let backgroundColor = pageContext.page?.sections?.[1]?.styles?.styles?.backgroundColor?.active
      ? pageContext.page.sections[1].styles.styles.backgroundColor
      : undefined;

    if (!backgroundColor && pageContext.page?.sections?.[1]?.styles?.styles?.bg?.active) {
      backgroundColor = pageContext.page.sections[1].styles.styles.bg;
    }

    let bgColor;
    if (backgroundColor?.solid) {
      bgColor = formColor(backgroundColor, false, backgroundColor.opacity, undefined, pageContext.themeData.colors);
    }

    return (
      <div>
        <div ref={this.sentinel} />
        <>
          {pageContext?.page?.sections?.[1]?.styles?.image?.id &&
            pageContext?.page?.sections?.[1]?.styles?.image?.active && (
              <Image
                id={pageContext.page.sections[1].styles.image.id}
                sizes="100vw"
                alt="Background"
                imageStyleName="sectionBackgroundImageFull404"
                images={pageContext.images}
              />
            )}
          <div style={bgColor} className={bg} />
        </>
        <NavHead noInfoRow isScrolling={this.state.isScrolling} pageContext={pageContext} />
        <div
          style={{
            backgroundColor: '#fff',
            width: '100%',
            color: '#444',
            marginTop: overlay ? '200px' : '20px',
            fontSize: '30px',
            fontWeight: 600,
            height: '120px',
            textAlign: 'center',
          }}
        >
          {text}
        </div>
        {pageContext.footer && (
          <LayoutContainer
            pageContext={pageContext}
            key={`Layout_${pageContext.footer._id}`}
            section={pageContext.footer}
            matches={this.state.matches}
          />
        )}
        {pageContext.cookie &&
          pageContext.cookie.active === true &&
          cookies &&
          cookies.cookies &&
          cookies.cookies.cookiesAccepted === undefined &&
          ready === true && (
            <CookieWarning
              themeData={pageContext.themeData}
              cookie={pageContext.cookie}
              pagePathList={pageContext.pagePathList}
              articlePathList={pageContext.articlePathList}
              categoryPathList={pageContext.categoryPathList}
              buttons={pageContext.buttons}
              images={pageContext.images}
              trackers={pageContext.seo.trackers}
              id={pageContext.page._id}
            />
          )}
      </div>
    );
  }
}

export default ErrorView;
